'use client';

import React, { useEffect, useState } from 'react';

import Link from 'next/link';
import { useSearchParams } from 'next/navigation';

import { motion } from 'framer-motion';
import { X } from 'lucide-react';

const Tournaments = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [hiddenTournamentst, setHiddenTournamentst] = useState<string | null>(null);
  const [hiddenBtn, setHiddenBtn] = useState(false);
  const searchParams = useSearchParams();
  const provider = searchParams.get('provider');
  const gamecode = searchParams.get('gamecode');

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setHiddenTournamentst(localStorage.getItem('hiddenTournaments'));
    }
  }, [hiddenBtn]);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleClose = () => {
    const currentTime = new Date().getTime();
    localStorage.setItem('hiddenTournaments', 'true');
    localStorage.setItem('hiddenTournamentsTimeout', (currentTime + 300000).toString());
    setHiddenBtn(true);
  };

  useEffect(() => {
    const currentTime = new Date().getTime();
    const hiddenTournamentsTimeouts = localStorage.getItem('hiddenTournamentsTimeout');

    if (hiddenTournamentsTimeouts && currentTime > Number(hiddenTournamentsTimeouts)) {
      localStorage.removeItem('hiddenTournaments');
      setHiddenBtn(false);
    }
  }, []);

  const neonVariants = {
    initial: {
      filter: 'drop-shadow(0 0 2px #a7e1f8) drop-shadow(0 0 4px #a7e1f8) drop-shadow(0 0 6px #29abe2) drop-shadow(0 0 8px #29abe2) drop-shadow(0 0 10px #1c7a9f)',
    },
    animate: {
      filter: [
        'drop-shadow(0 0 2px #a7e1f8) drop-shadow(0 0 4px #a7e1f8) drop-shadow(0 0 6px #29abe2) drop-shadow(0 0 8px #29abe2) drop-shadow(0 0 10px #1c7a9f)',
        'drop-shadow(0 0 1px #a7e1f8) drop-shadow(0 0 2px #a7e1f8) drop-shadow(0 0 3px #29abe2) drop-shadow(0 0 4px #29abe2) drop-shadow(0 0 5px #1c7a9f)',
        'drop-shadow(0 0 2px #a7e1f8) drop-shadow(0 0 4px #a7e1f8) drop-shadow(0 0 6px #29abe2) drop-shadow(0 0 8px #29abe2) drop-shadow(0 0 10px #1c7a9f)',
      ],
      transition: {
        duration: 2,
        repeat: Infinity,
        ease: 'easeInOut',
      },
    },
  };

  return (
    <motion.div
      className={`rounded-2xl fixed z-50 top-[50%] bottom-[50%] w-[211px] h-[111px] -mt-[55px] right-[5px] transition-all duration-300 ease-in-out bgTournamentsA pl-[70px] pr-[15px] flex justify-center items-center ${isOpen ? 'translate-x-0' : 'translate-x-full'} ${!provider && !gamecode ? 'block' : 'hidden'} ${hiddenTournamentst === 'true' ? 'hidden' : 'block'}`}
      variants={neonVariants}
      initial="initial"
      animate="animate"
    >
      <div onClick={handleToggle} className="absolute -top-[15px] -left-[60px] bgTournamentsC" />
      <X className="absolute -top-[7px] -left-[60px] text-slategray cursor-pointer" onClick={handleClose} />
      <div className="font-medium text-sm text-center">
        <Link href="/torneos" className="w-[211px] h-[111px]">
          🎲🎰70.000L en premios!!, participa en el torneo dollarmania ya!💸
        </Link>
      </div>
    </motion.div>
  );
};

export default Tournaments;
